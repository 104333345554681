import React, { Fragment } from "react";
import Skill from "./Skill";
import SkillSection from "./SkillSection";
import python from "../../assets/skills/python.png";
import django from "../../assets/skills/django.jpg";
import node from "../../assets/skills/node.png";
import flask from "../../assets/skills/flask.jpg";
import express from "../../assets/skills/express.jpg";
import netcore from "../../assets/skills/netcore.png";
import csharp from "../../assets/skills/csharp.png";

const BackEnd = () => {
  const createIcon = (img, altText) => {
    const style = {
      background: `url(${img})`,
      height: "1.5rem",
      width: "1.5rem",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "50%",
    };
    return <div style={style} alt={altText} />;
  };
  const skills = [
    {
      text: "Python",
      value: 90,
      icon: createIcon(python, "Python"),
    },
    {
      text: "Django",
      value: 70,
      icon: createIcon(django, "Django"),
    },
    {
      text: "Flask",
      value: 60,
      icon: createIcon(flask, "Flask"),
    },
    {
      text: "Node",
      value: 55,
      icon: createIcon(node, "Node.js"),
    },
    {
      text: "Express",
      value: 60,
      icon: createIcon(express, "Express.js"),
    },
    {
      text: ".NET Core",
      value: 25,
      icon: createIcon(netcore, ".NET Core"),
    },
    {
      text: "C#",
      value: 30,
      icon: createIcon(csharp, "C#"),
    },
  ];
  const createSkills = (skillObject) => {
    return (
      <Fragment>
        {skillObject.map((skill) => (
          <Skill text={skill.text} value={skill.value} icon={skill.icon} />
        ))}
      </Fragment>
    );
  };
  return (
    <SkillSection title="Back-End" skillComponents={createSkills(skills)} />
  );
};

export default BackEnd;
