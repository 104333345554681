import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import femaLogo from "../assets/logo-fema.png";
import dukeLogo from "../assets/logo-duke.png";
import npsLogo from "../assets/logo-nps.png";
import ucdLogo from "../assets/logo-ucd.jpg";
import denverLogo from "../assets/logo-denver.png";
import foodbankLogo from "../assets/logo-foodbank.png";
import utLogo from "../assets/logo-ut.png";
import styles from "../Styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  exp: {},
  logo: {
    width: "11rem",
  },
  npsLogo: {
    width: "3rem",
  },
  dukeLogo: {
    height: "4rem",
  },
  date: {
    margin: "0 2rem",
    color: styles.color,
    [theme.breakpoints.down("sm")]: {
      color: "#000",
      margin: 0,
    },
  },
  container: {
    minWidth: "36rem",
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto !important",
    },
  },
  altIcon: {},
}));

const timelineStyles = {
  workIcon: {
    background: "rgb(33, 150, 243)",
    color: "#fff",
  },
  schoolIcon: {
    background: "rgb(233, 30, 99)",
    color: "#fff",
  },
  starIcon: { background: "rgb(16, 204, 82)", color: "#fff" },
  contentArrow: {
    borderRight: "7px solid  rgb(33, 150, 243)",
  },
  subtitle: {
    fontWeight: 500,
  },
};

const Experience = () => {
  const classes = useStyles();

  return (
    <VerticalTimeline>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={timelineStyles.workIcon}
        contentArrowStyle={timelineStyles.contentArrow}
        date="2021 - present"
        iconStyle={timelineStyles.workIcon}
        icon={<WorkIcon />}
        dateClassName={classes.date}
        textClassName={classes.leftText}
        // className={classes.container}
        iconClassName={classes.icon}
      >
        <img className={classes.dukeLogo} src={dukeLogo} alt="Duke Energy" />
        <h3 className="vertical-timeline-element-title">
          Lead Full-Stack Developer
        </h3>
        <h4
          className="vertical-timeline-element-subtitle"
          style={timelineStyles.subtitle}
        >
          Duke Energy
        </h4>
        <p></p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentArrowStyle={timelineStyles.contentArrow}
        date="2018 - 2021"
        iconStyle={timelineStyles.workIcon}
        icon={<WorkIcon />}
        dateClassName={classes.date}
        textClassName={classes.leftText}
        // className={classes.container}
        iconClassName={classes.icon}
      >
        <img className={classes.logo} src={femaLogo} alt="FEMA" />
        <h3 className="vertical-timeline-element-title">
          Full-Stack Developer
        </h3>
        <h4
          className="vertical-timeline-element-subtitle"
          style={timelineStyles.subtitle}
        >
          Federal Emergency Management Agency
        </h4>
        <p></p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2017 - 2018"
        iconStyle={timelineStyles.workIcon}
        icon={<WorkIcon />}
        dateClassName={classes.date}
        textClassName={classes.rightText}
        // className={classes.container}
        iconClassName={classes.icon}
      >
        <img
          className={classes.npsLogo}
          src={npsLogo}
          alt="National Park Service"
        />
        <h3 className="vertical-timeline-element-title">Web Developer</h3>
        <h4
          className="vertical-timeline-element-subtitle"
          style={timelineStyles.subtitle}
        >
          National Park Service
        </h4>
        <p></p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2018"
        iconStyle={timelineStyles.schoolIcon}
        icon={<SchoolIcon />}
        dateClassName={classes.date}
        textClassName={classes.leftText}
        // className={classes.container}
        iconClassName={classes.icon}
      >
        <img className={classes.logo} src={ucdLogo} alt="UCDenver" />
        <h3 className="vertical-timeline-element-title">
          Masters in Geospatial Science
        </h3>
        <h4
          className="vertical-timeline-element-subtitle"
          style={timelineStyles.subtitle}
        >
          University of Colorado - Denver
        </h4>
        <p></p>
      </VerticalTimelineElement>
      {/* <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2016 - 2017"
        iconStyle={timelineStyles.workIcon}
        icon={<WorkIcon />}
        dateClassName={classes.date}
        textClassName={classes.rightText}
        // className={classes.container}
        iconClassName={classes.icon}
      >
        <img
          className={classes.logo}
          src={denverLogo}
          alt="City and County of Denver"
        />
        <h3 className="vertical-timeline-element-title">
          Transportation Planning
        </h3>
        <h4
          className="vertical-timeline-element-subtitle"
          style={timelineStyles.subtitle}
        >
          City and County of Denver
        </h4>
        <p></p>
      </VerticalTimelineElement> */}
      {/* <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2015 - 2016"
        iconStyle={timelineStyles.workIcon}
        icon={<WorkIcon />}
        dateClassName={classes.date}
        textClassName={classes.leftText}
        // className={classes.container}
        iconClassName={classes.icon}
      >
        <img
          className={classes.logo}
          src={foodbankLogo}
          alt="Central Texas Food Bank"
        />
        <h3 className="vertical-timeline-element-title">Program Coordinator</h3>
        <h4
          className="vertical-timeline-element-subtitle"
          style={timelineStyles.subtitle}
        >
          Central Texas Food Bank
        </h4>
        <p></p>
      </VerticalTimelineElement> */}
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        date="2015"
        iconStyle={timelineStyles.schoolIcon}
        icon={<SchoolIcon />}
        dateClassName={classes.date}
        textClassName={classes.rightText}
        // className={classes.container}
        iconClassName={classes.icon}
      >
        <img className={classes.logo} src={utLogo} alt="University of Texas" />
        <h3 className="vertical-timeline-element-title">
          Bachelors in Geography (GIS)
        </h3>
        <h4
          className="vertical-timeline-element-subtitle"
          styles={timelineStyles.subtitle}
        >
          University of Texas at Austin
        </h4>
        <p className={classes.pWhite}></p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
};

export default Experience;
