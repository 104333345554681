import React from "react";
import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
    position: "relative",
    overflow: "hidden",
  },
  subject: {
    margin: "1rem 0",
  },
  message: {
    margin: "1rem 0",
  },
  smallText: {
    zIndex: 20,
    color: "#fff",
    fontSize: "0.8rem",
  },
  text: {
    zIndex: 20,
    color: "#fff",
    fontSize: "1.4rem",
    fontWeight: "bold",
    marginBottom: "4rem",
  },
  headColor: {
    background: "#3f51b5",
    width: "150%",
    height: "9rem",
    position: "absolute",
    top: "-9%",
    left: "-15%",
    transform: "rotate(-3deg)",
    zIndex: 10,
  },
  send: {
    background: "#3f51b5",
    maxWidth: "40%",
    alignSelf: "flex-end",
  },
  sendIcon: {
    marginLeft: "0.5rem",
    height: "1.2rem",
  },
});

const Contact = () => {
  const classes = useStyles();

  const sendMessage = () => {
    const subject = document.querySelector("#subject").value;
    const message = document.querySelector("#message").value;
    console.log(subject);
    window.open(
      `mailto:jraines521@gmail.com?Subject=${subject}&Body=${message}`
    );
  };
  return (
    <Paper className={classes.root}>
      <div className={classes.headColor} />
      <span className={classes.smallText}>Send James a message</span>
      <span className={classes.text}>Let's build an app</span>
      <TextField
        className={classes.subject}
        id="subject"
        label="Subject"
        variant="outlined"
        placeholder="Hi James - Big fan here!"
      />
      <TextField
        className={classes.message}
        id="message"
        label="Message"
        variant="outlined"
        multiline
        rows={6}
        placeholder="I want to build something that will change the world..."
      />
      <Button
        className={classes.send}
        variant="contained"
        color="primary"
        onClick={sendMessage}
      >
        Send
        <SendIcon className={classes.sendIcon} />
      </Button>
    </Paper>
  );
};

export default Contact;
