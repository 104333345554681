import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const HazPy = ({ maxWidth }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiCardMedia-root": {
        [theme.breakpoints.down("sm")]: {
          backgroundSize: "100%",
        },
      },
    },
    media: {
      height: "20rem",
      [theme.breakpoints.down("sm")]: {
        height: "10rem",
      },
    },
    content: {
      background: "#fff",
    },
  }));
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={require("../../assets/project-hazpy.png")}
          title="Hazus Python"
        />
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" component="h2">
            HazPy
          </Typography>
          <Typography gutterBottom variant="h7" component="h3">
            Engineer and Project Manager
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            The Hazus Python Package is an open-source version of the methods
            used in the nation's authoritative natural disaster loss estimation
            modeling software, Hazus (https://www.fema.gov/hazus). This was
            developed to decouple the UI from the methods, provide transparent
            science, and to allow cooperation and integration across academic
            and scientific communities across the world.
          </Typography>
          <Typography gutterBottom variant="h7" component="h3">
            Stack
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Python
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default HazPy;
