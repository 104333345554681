import React from "react";
import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Bio from "./components/Bio";
import HazusLossLibrary from "./components/mainprojects/HazusLossLibrary";
import HazPy from "./components/mainprojects/HazPy";
import NPMapLive from "./components/mainprojects/NPMapLive";
import Projects from "./components/projects/Projects";
import background from "./assets/bg.jpg";
import Experience from "./components/Experience";
import FrontEnd from "./components/skills/FrontEnd";
import BackEnd from "./components/skills/BackEnd";
import Databases from "./components/skills/Databases";
import Pro from "./components/skills/Pro";
import Contact from "./components/Contact";
import Masonry from "react-masonry-css";

// Redux
import { Provider } from "react-redux";
import store from "./store";

const gridSpacing = "1.5rem";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "2rem",
    paddingBottom: "2rem",
    width: "auto",
  },
  gridRoot: {
    flexGrow: 1,
    justifyContent: "center",
  },
  background: {
    background: `url(${background}) no-repeat`,
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    position: "fixed",
    backgroundSize: "cover",
    zIndex: -1,
  },
  topContent: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  bio: {
    margin: `0.5rem ${gridSpacing} ${gridSpacing} 0rem`,
    [theme.breakpoints.down("sm")]: {
      margin: "-1rem 0 1rem 0",
      display: "flex",
      justifyContent: "center",
    },
  },
  carousel: {
    margin: `0.5rem 0rem ${gridSpacing} 0.5rem`,
    flex: 1,
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      margin: "1rem 0",
    },
  },
  gridItem: {
    maxWidth: "50%",
    minWidth: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
  },
  masonryGrid: {
    // display: "-webkit-box" /* Not needed if autoprefixing */,
    // display: "-ms-flexbox" /* Not needed if autoprefixing */,
    display: "flex",
    marginLeft: `-${gridSpacing}` /* gutter size offset */,
    width: "auto",
  },
  masonryGridColumn: {
    paddingLeft: gridSpacing /* gutter size */,
    backgroundClip: "padding-box",
  },
  masonryGridItem: {
    marginBottom: gridSpacing,
  },
}));

const App = () => {
  const classes = useStyles();
  return (
    <Provider store={store}>
      <Container className={classes.root}>
        <div className={classes.background} />
        <div className={classes.topContent}>
          <div className={classes.bio}>
            <Bio />
          </div>
          <div className={classes.carousel}>
            <Projects />
          </div>
        </div>
        <Masonry
          breakpointCols={{ default: 2, 700: 1 }}
          className={classes.masonryGrid}
          columnClassName={classes.masonryGridColumn}
        >
          <div className={classes.masonryGridItem}>
            <HazusLossLibrary />
          </div>
          <div className={classes.masonryGridItem}>
            <HazPy />
          </div>
          <div className={classes.masonryGridItem}>
            <NPMapLive />
          </div>
          <div className={classes.masonryGridItem}>
            <FrontEnd />
          </div>
          <div className={classes.masonryGridItem}>
            <BackEnd />
          </div>
          <div className={classes.masonryGridItem}>
            <Databases />
          </div>
          <div className={classes.masonryGridItem}>
            <Pro />
          </div>
          <div className={classes.masonryGridItem}>
            <Experience />
          </div>
          <div className={classes.masonryGridItem}>
            <Contact />
          </div>
        </Masonry>
      </Container>
    </Provider>
  );
};

export default App;
