import { TOGGLE_CAROUSEL } from "./types";

export const toggleCarousel = () => (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_CAROUSEL,
      payload: null,
    });
  } catch (error) {
    console.error("Unable to toggle carousel");
  }
};
