import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const NPMapLive = ({ maxWidth }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiCardMedia-root": {
        [theme.breakpoints.down("sm")]: {
          backgroundSize: "100%",
        },
      },
    },
    media: {
      height: "20rem",
      [theme.breakpoints.down("sm")]: {
        height: "10rem",
      },
    },
    content: {
      background: "#fff",
    },
  }));
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={require("../../assets/project-npmap.png")}
          title="Hazus Loss Library"
        />
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" component="h2">
            NP Map Live
          </Typography>
          <Typography gutterBottom variant="h7" component="h3">
            Engineer
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            NP Map Live is the National Park Services internal map builder
            platform and geospatial inventory management system. Park assets,
            such as roads, campgrounds, and other inventory can be viewed and
            modified in real time syncing across NPS maps. This product is meant
            to replace the NP Map Builder and provide management functionality.
            It is still under production.
          </Typography>
          <Typography gutterBottom variant="h7" component="h3">
            Stack
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Angular (TypeScript) | .Net CORE (C#) | SQL Server (T-SQL)
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default NPMapLive;
