import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles({
  skill: {
    display: "flex",
    alignItems: "center",
  },
  progress: {
    margin: "1rem 1rem 0.7rem 1rem",
    borderRadius: "50px",
    height: "10px",
    flex: 1,
  },
  text: {
    margin: "0 0.5rem",
  },
  icon: {
    height: "1.5rem",
    borderRadius: "50%",
  },
});

const Skill = ({ text, value, icon }) => {
  const classes = useStyles();
  return (
    <div className={classes.skill}>
      {icon}
      <span className={classes.text}>{text}</span>
      <LinearProgress
        className={classes.progress}
        variant="determinate"
        value={value}
      />
    </div>
  );
};

export default Skill;
