import React, { Fragment } from "react";
import Skill from "./Skill";
import SkillSection from "./SkillSection";
import postgresql from "../../assets/skills/postgresql.png";
import postgis from "../../assets/skills/postgis.png";
import sqlserver from "../../assets/skills/sqlserver.png";
import sqlite from "../../assets/skills/sqlite.png";
import mongo from "../../assets/skills/mongo.jpg";
import sql from "../../assets/skills/nosql.jpg";
import nosql from "../../assets/skills/sql.jpg";

const Databases = () => {
  const createIcon = (img, altText) => {
    const style = {
      background: `url(${img})`,
      height: "1.5rem",
      width: "1.5rem",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "50%",
    };
    return <div style={style} alt={altText} />;
  };
  const skills = [
    {
      text: "PostgreSQL",
      value: 85,
      icon: createIcon(postgresql, "PostgreSQL"),
    },
    {
      text: "PostGIS",
      value: 90,
      icon: createIcon(postgis, "PostGIS"),
    },
    {
      text: "SQL Server",
      value: 80,
      icon: createIcon(sqlserver),
    },
    {
      text: "SQLite",
      value: 90,
      icon: createIcon(sqlite, "SQLite"),
    },
    {
      text: "MongoDB",
      value: 65,
      icon: createIcon(mongo, "MongoDB"),
    },
    {
      text: "SQL",
      value: 85,
      icon: createIcon(sql, "SQL"),
    },
    {
      text: "NoSQL",
      value: 60,
      icon: createIcon(nosql, "NoSQL"),
    },
  ];

  const createSkills = (skillObject) => {
    return (
      <Fragment>
        {skillObject.map((skill) => (
          <Skill text={skill.text} value={skill.value} icon={skill.icon} />
        ))}
      </Fragment>
    );
  };
  return (
    <SkillSection title="Databases" skillComponents={createSkills(skills)} />
  );
};

export default Databases;
