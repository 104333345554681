import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../Styles";

const useStyles = makeStyles({
  skillSection: {
    background: styles.background,
    color: styles.color,
    padding: "1rem",
    margin: "1rem 0",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "5px",
  },
  skillSectionTitle: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
});

const SkillSection = ({ title, skillComponents }) => {
  const classes = useStyles();
  return (
    <div className={classes.skillSection}>
      <span className={classes.skillSectionTitle}>{title}</span>
      {skillComponents}
    </div>
  );
};

export default SkillSection;
