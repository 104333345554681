import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const HazusLossLibrary = ({ maxWidth }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiCardMedia-root": {
        [theme.breakpoints.down("sm")]: {
          backgroundSize: "100%",
        },
      },
    },
    media: {
      height: "20rem",
      [theme.breakpoints.down("sm")]: {
        height: "10rem",
      },
    },
    content: {
      background: "#fff",
    },
  }));
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={require("../../assets/project-hll.png")}
          title="Hazus Loss Library"
        />
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" component="h2">
            Hazus Loss Library
          </Typography>
          <Typography gutterBottom variant="h7" component="h3">
            Lead Engineer and Project Manager
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            The Hazus Loss Library is the nation's repository for accessing
            authoritative risk information. This platform increases the
            accessibility of risk information by providing formats for emergency
            managers, mitigation planners, grant writers, and analysts.
          </Typography>
          <Typography gutterBottom variant="h7" component="h3">
            Stack
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            React (JavaScript) | Django (Python) | PostgreSQL & PostGIS (PL/SQL)
            | AWS (Elastic Beanstalk, RDS, S3, CloudFront)
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default HazusLossLibrary;
