import React, { Fragment } from "react";
import Skill from "./Skill";
import SkillSection from "./SkillSection";
import react from "../../assets/skills/react.png";
import redux from "../../assets/skills/redux.png";
import angular from "../../assets/skills/angular.png";
import javascript from "../../assets/skills/javascript.png";
import html from "../../assets/skills/html.jpg";
import css from "../../assets/skills/css.png";
import typescript from "../../assets/skills/typescript.png";
import leaflet from "../../assets/skills/leaflet.jpg";

const FrontEnd = () => {
  const createIcon = (img, altText) => {
    const style = {
      background: `url(${img})`,
      height: "1.5rem",
      width: "1.5rem",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "50%",
    };
    return <div style={style} alt={altText} />;
  };
  const skills = [
    {
      text: "React",
      value: 85,
      icon: createIcon(react, "React"),
    },
    {
      text: "React Native",
      value: 60,
      icon: createIcon(react, "React"),
    },
    {
      text: "Redux",
      value: 80,
      icon: createIcon(redux, "Redux"),
    },
    {
      text: "Angular",
      value: 60,
      icon: createIcon(angular, "Angular"),
    },
    {
      text: "TypeScript",
      value: 50,
      icon: createIcon(typescript, "TypeScript"),
    },
    {
      text: "JavaScript",
      value: 90,
      icon: createIcon(javascript, "JavaScript"),
    },
    {
      text: "HTML",
      value: 95,
      icon: createIcon(html, "HTML"),
    },
    {
      text: "CSS",
      value: 90,
      icon: createIcon(css, "CSS"),
    },
    {
      text: "Leaflet.js",
      value: 90,
      icon: createIcon(leaflet, "Leaflet"),
    },
  ];

  const createSkills = (skillObject) => {
    return (
      <Fragment>
        {skillObject.map((skill) => (
          <Skill text={skill.text} value={skill.value} icon={skill.icon} />
        ))}
      </Fragment>
    );
  };
  return (
    <SkillSection title="Front-End" skillComponents={createSkills(skills)} />
  );
};

export default FrontEnd;
