import React from "react";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import EmailIcon from "@material-ui/icons/Email";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import DescriptionIcon from "@material-ui/icons/Description";
import styles from "../Styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: styles.background,
    color: "#d9d9d9",
    maxWidth: "25rem",
  },
  media: {
    height: "20rem",
  },
  content: {
    background: styles.background,
    color: "#d9d9d9",
  },
  listDividers: {
    width: "100%",
    background: `${styles.background}`,
    color: "#d9d9d9",
  },
  listItem: {
    background: styles.background,
    color: "#d9d9d9",
  },
  listIcon: {
    color: "#d9d9d9",
    marginRight: "1rem",
  },
  name: {
    fontSize: "1.7rem",
    fontWeight: "bold",
  },
}));

const Bio = () => {
  const classes = useStyles();

  const ListDividers = () => {
    return (
      <List
        component="nav"
        className={classes.listDividers}
        aria-label="mailbox folders"
      >
        <ListItem
          button
          className={classes.listItem}
          onClick={() =>
            window.open(require("../assets/James-Raines-Resume.pdf"), "_blank")
          }
        >
          <DescriptionIcon className={classes.listIcon} />
          <ListItemText primary="Resume" />
        </ListItem>
        <ListItem
          button
          className={classes.listItem}
          onClick={() =>
            window.open(
              "https://www.google.com/maps/place/Austin,+TX/",
              "_blank"
            )
          }
        >
          <PersonPinCircleIcon className={classes.listIcon} />
          <ListItemText primary="Austin, TX" />
        </ListItem>
        <Divider />
        <ListItem
          button
          className={classes.listItem}
          onClick={() =>
            window.open(
              "mailto:jraines521@gmail.com?Subject=Hi James - Big Fan Here"
            )
          }
        >
          <EmailIcon className={classes.listIcon} />
          <ListItemText primary="Jraines521@gmail.com" />
        </ListItem>
        <Divider />
        <ListItem
          className={classes.listItem}
          button
          divider
          onClick={() => window.open("tel:+15126942714")}
        >
          <SmartphoneIcon className={classes.listIcon} />
          <ListItemText primary="(512) 694-2714" />
        </ListItem>
        {/* <ListItem
          className={classes.listItem}
          button
          onClick={() => window.open("https://github.com/lorax521", "_blank")}
        >
          <GitHubIcon className={classes.listIcon} />
          <ListItemText primary="GitHub" />
        </ListItem> */}
        <Divider light />
        <ListItem
          className={classes.listItem}
          button
          onClick={() =>
            window.open(
              "https://www.linkedin.com/in/james-raines-95aa8157",
              "_blank"
            )
          }
        >
          <LinkedInIcon className={classes.listIcon} />
          <ListItemText primary="LinkedIn" />
        </ListItem>
      </List>
    );
  };

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={require("../assets/me2.jpg")}
          title="James Raines"
        />
        <CardContent className={classes.content}>
          <Typography
            className={classes.name}
            gutterBottom
            variant="h5"
            component="h2"
          >
            James Raines
          </Typography>
          <Typography gutterBottom variant="h6" component="h3">
            Full-Stack Developer
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.content}
          >
            My name is James Raines - I am a lead full-stack developer for Duke
            Energy and owner of a private consulting LLC, Cotula Labs. I
            specialize in data driven applications, advanced visualization, and
            geospatial architecture.
          </Typography>
          {/* <br />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.content}
          >
            Let me make a beautiful, performant app for you, just like the web
            applications and analytical models I have developed for multiple
            federal agencies and individuals. Reach out via email or phone for
            consulting.
          </Typography> */}
          <br />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.content}
          >
            Due to the sensitive nature of my work under DHS and for public
            utilities, the majority of my portfolio is private. I am happy to
            provide more advanced examples of my work in individual
            conversations.
          </Typography>
        </CardContent>
      </CardActionArea>
      <ListDividers />
    </Card>
  );
};

export default Bio;
