import React, { useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import UIUX from "./UIUX";
import DataApplications from "./DataApplications";
import MappingApplications from "./MappingApplications";
import OpenSourceTools from "./OpenSourceTools";
import PythonPackages from "./PythonPackages";
import Personas from "./Personas";
import ScientificApps from "./ScientificApps";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import styles from "../../Styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const maxWidth = "50rem";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  tabs: {
    borderRadius: "0 0 5px 5px",
  },
  tab: {
    height: "100%",
    minWidth: "auto",
    background: styles.background,
  },
  ".MuiTab-root": {
    minWidth: "0 !important",
  },
  swipeableViews: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "25rem",
    },
    borderRadius: "5px 5px 0 0",
    "&:.react-swipeable-view-container": {
      height: "100%",
    },
  },
  tabPanel: {
    // borderRadius: "0 0 5px 5px",
    background: styles.background,
    color: "#d9d9d9",
    height: "100%",
    minHeight: "100%",
    "& .MuiBox-root": {
      height: "100%",
      minHeight: "100%",
      padding: 0,
      "& .MuiTypography-root": {
        height: "100%",
        minHeight: "100%",
      },
    },
  },
  appBar: {
    borderRadius: "0 0 7px 7px",
  },
}));

const elements = [
  {
    title: "Data Applications",
    component: <DataApplications maxWidth={maxWidth} />,
  },
  {
    title: "Mapping Applications",
    component: <MappingApplications maxWidth={maxWidth} />,
  },
  {
    title: "Open Source Tools",
    component: <OpenSourceTools maxWidth={maxWidth} />,
  },
  {
    title: "Python Packages",
    component: <PythonPackages maxWidth={maxWidth} />,
  },
  { title: "UI/UX Personas", component: <Personas maxWidth={maxWidth} /> },
  { title: "UI/UX Prototyping", component: <UIUX maxWidth={maxWidth} /> },
  {
    title: "Scientific Applications",
    component: <ScientificApps maxWidth={maxWidth} />,
  },
];
const Projects = ({ increment }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const progressionSpeed = 3000;
  const maxValues = elements.length - 1;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const TabPanelComponents = elements.map((element, index) => {
    return (
      <TabPanel
        className={classes.tabPanel}
        style={Object.assign({}, { minHeight: "100%" })}
        value={value}
        index={index}
        key={`tab-panel-${index}`}
        dir={theme.direction}
      >
        {element.component}
      </TabPanel>
    );
  });
  const TabComponents = elements.map((element, index) => {
    return (
      <Tab
        className={classes.tab}
        label={<FiberManualRecordIcon />}
        {...a11yProps(index)}
      />
    );
  });

  useEffect(() => {
    const progress = window.setInterval(() => {
      if (increment) {
        setValue((value) => (value < maxValues ? value + 1 : 0));
      }
    }, progressionSpeed);
    return () => window.clearInterval(progress);
  }, [increment, maxValues]);
  return (
    <div className={classes.root}>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        className={classes.swipeableViews}
        containerStyle={{ height: "100%" }}
        disableLazyLoading={false}
      >
        {TabPanelComponents}
      </SwipeableViews>
      <AppBar className={classes.appBar} position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="#1976d2"
          textColor="primary"
          variant="fullWidth"
          aria-label="tabs"
          className={classes.tabs}
        >
          {TabComponents}
        </Tabs>
      </AppBar>
    </div>
  );
};

Projects.propTypes = {
  increment: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  increment: state.carousel,
});

export default connect(mapStateToProps, {})(Projects);
