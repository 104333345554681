import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import styles from "../../Styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toggleCarousel } from "../../actions/carousel";
import image from "../../assets/carousel-uiux.png";
import Button from "@material-ui/core/Button";

const UIUX = ({ toggleCarousel }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    header: {
      width: "100%",
      background: styles.background,
      padding: "0.5rem 1rem",
    },
    title: {
      color: styles.color,
      fontSize: "1.2rem",
    },
    image: {
      background: `url("${image}")`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      width: "100%",
      height: "100%",
      borderRadius: 0,
    },
  }));
  const classes = useStyles();

  const onClick = () => {
    toggleCarousel();
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <span className={classes.title}>UI/UX Prototyping</span>
      </div>
      <Button className={classes.image} onClick={onClick}></Button>
    </Paper>
  );
};

UIUX.propTypes = {
  toggleCarousel: PropTypes.func.isRequired,
};

export default connect(null, { toggleCarousel })(UIUX);
