import React, { Fragment } from "react";
import Skill from "./Skill";
import SkillSection from "./SkillSection";
import arcgis from "../../assets/skills/arcgis.png";
import ml from "../../assets/skills/ml.png";
import r from "../../assets/skills/r.png";
import adobe from "../../assets/skills/adobe.jpg";
import git from "../../assets/skills/git.png";
import agile from "../../assets/skills/agile.jpg";
import aws from "../../assets/skills/aws.png";
import docker from "../../assets/skills/docker.png";
import hazus from "../../assets/skills/hazus.jpg";

const Pro = () => {
  const createIcon = (img, altText) => {
    const style = {
      background: `url(${img})`,
      height: "1.5rem",
      width: "1.5rem",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "50%",
    };
    return <div style={style} alt={altText} />;
  };
  const skills = [
    {
      text: "ArcGIS",
      value: 95,
      icon: createIcon(arcgis, "ArcGIS"),
    },
    {
      text: "Machine Learning",
      value: 45,
      icon: createIcon(ml, "Machine Learning"),
    },
    {
      text: "R",
      value: 55,
      icon: createIcon(r, "R"),
    },
    {
      text: "Adobe CC",
      value: 70,
      icon: createIcon(adobe, "Adobe CC"),
    },
    {
      text: "Git",
      value: 85,
      icon: createIcon(git, "Git"),
    },
    {
      text: "Agile",
      value: 75,
      icon: createIcon(agile, "Agile"),
    },
    {
      text: "AWS",
      value: 65,
      icon: createIcon(aws, "AWS"),
    },
    {
      text: "Hazus",
      value: 95,
      icon: createIcon(hazus, "Hazus"),
    },
    {
      text: "Docker",
      value: 55,
      icon: createIcon(docker, "Docker"),
    },
  ];

  const createSkills = (skillObject) => {
    return (
      <Fragment>
        {skillObject.map((skill) => (
          <Skill text={skill.text} value={skill.value} icon={skill.icon} />
        ))}
      </Fragment>
    );
  };
  return (
    <SkillSection title="Professional" skillComponents={createSkills(skills)} />
  );
};

export default Pro;
